* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --face-ht-ref: 50vh;
  --pic-scale-ref: 100%;
  --banner-scale-ref: calc(var(--pic-scale-ref)/2);
}

body, html { 
  height: 100%;
  font-family: 'Fredoka', sans-serif;
  font-weight: 700;
}

.background-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;  
  background-image: url('https://i.ibb.co/Dr1n6pS/lim-snow-1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.header-section {
  color: yellow;
  position: sticky;
  top: 0;
}

.App {
  color: black;
  display: flex;
  height: 100vh;
}

@media (max-width: 615px) {
  .body-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
  }
  .self-img {
    max-height: 40vh;
    border-radius: 3px;
    box-shadow: 4px 4px 6px black;
    opacity: 0.9;
  }
  .router-container {
    max-width: 80%;
    max-height: 80%;
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
  }

  .icons {
    margin: 0 0 10px 0;
    
  }
}

@media (min-width: 616px) {
  .body-section {
    display: flex;
    margin: 0 0 0 10px;
  }
  
  .self-img {
    max-height: 50vh;
    border-radius: 3px;
    box-shadow: 4px 4px 6px black;
    opacity: 0.9;
  }

  .router-container {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    overflow-y: visible;
  }

  .icons {
    margin: 10px 0 0 0;
  }
}

h1, h2, h3, h4, h5 {
  color: orangered;
  font-family: 'Fredoka', sans-serif;
  font-weight: 700;
}

.title-h1 {
  text-shadow: 
  0 0 3px #fff,
  0 0 5px #fff,
  0 0 10px #fff,
  0 0 21px #0fa,
  0 0 41px #0fa,
  0 0 46px #0fa,
  /* 0 0 51px #0fa, */
  0 0 75px #0fa;
}

.icons {
  display: flex;
  justify-content: space-evenly;
}

.icons .icon {
  width: 50px;
  height: 50px;
}

.footer-section {
  color: whitesmoke;
  width: 100%;
  position: absolute;
  display: block;
  text-align: right;
  bottom: 0;
}

.footer-section p {
  color: whitesmoke;
  font-size: 0.5rem;
}

