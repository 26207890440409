.proj-container {
    font-family: 'Manrope', sans-serif;
    max-width: 70vw;
    max-height: 70vw;
}

.modal-body {
    padding: 0.2rem 0.1rem 0.1rem 0.1rem;
}

.carousel-caption {
    bottom: 0.1rem;
}

.carousel-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.close-proj-modal {
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    border-color: #7DF9FF;
    background-color: #FF69B4;
    font-family: 'Fredoka', sans-serif;
    font-weight: 700;
    color:#7DF9FF;
}

.header-section {
    color: yellow;
    position: sticky;
    top: 0;
}

.modal-body {
    background-color: #F0EAD6;
}

.span-text {
    line-height: 2rem;
}

.caption-group {
    background: hsl(0 0% 0% / 0.8);
    border-radius: 2px;
    width: 100%;
}

.caption-group > p {
    font-family: 'Manrope', sans-serif;
    color: #FCF5E5;
    text-shadow: 0 2px 3px #1B1212;
    font-size: 0.7rem;
}

.caption-group > h5 {
    font-family: 'Manrope', sans-serif;
    color: #E2DFD2;
    text-shadow: 0 2px 3px #1B1212;
}

.caption-group-2 {
    background: hsl(0 0% 0% / 0.7);
    border-radius: 2px;
}

.caption-group-2 > p {
    font-family: 'Manrope', sans-serif;
    color: #FCF5E5;
    text-shadow: 0 2px 3px #1B1212;
    font-size: 0.7rem;
}

.caption-group-2 > h5 {
    font-family: 'Manrope', sans-serif;
    color: #E2DFD2;
    text-shadow: 0 2px 3px #1B1212;
}

button[aria-label~='Slide'] {
    border: 2px solid white !important;
}

@keyframes bounce {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(12px);
    }
    100% {
        transform: translateX(0);
    }
}

.carousel-control-next-icon {
    animation: bounce 0.6s linear infinite;
}

@media (max-width: 615px) {
    .body-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: black;
    }
}

@media (min-width: 616px) {
    .body-section {
        display: flex;
        margin: 0 0 0 10px;
    }
}