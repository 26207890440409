.links ul {
    list-style-type: none;
    max-width: 100%;
    width: 100%;
}

.link-ul {
    color: black;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    padding-left: 10px;
}

.link1, .link2, .link3, .link4 {
    color: orangered; 
    text-shadow: 1.25px 1.25px 2px #7DF9FF;
    text-decoration: none;
    font-weight: bold;
    width: 100%;
    text-shadow: 
    0 0 3px #fff,
    0 0 5px #fff,
    0 0 10px #fff,
    0 0 21px #0fa,
    0 0 41px #0fa;
    /* 0 0 46px #0fa; */
    /* 0 0 51px #0fa, */
    /* 0 0 75px #0fa; */
}

.proj-wrapper > a {
    color: orangered;
    text-shadow: 1.25px 1.25px 2px #7DF9FF;
    text-decoration: none;
    font-weight: bold;
    width: 100%;
    text-shadow: 
    0 0 3px #fff,
    0 0 5px #fff,
    0 0 10px #fff,
    0 0 21px #0fa,
    0 0 41px #0fa;
    /* 0 0 46px #0fa; */
    /* 0 0 51px #0fa, */
    /* 0 0 75px #0fa; */
}

.proj-wrapper {
    text-shadow: 
    0 0 3px #fff,
    0 0 5px #fff,
    0 0 10px #fff,
    0 0 21px #0fa,
    0 0 41px #0fa,
    0 0 46px #0fa,
    /* 0 0 51px #0fa, */
    0 0 75px #0fa;
}

.link1:hover, .link2:hover, .link3:hover, .link4:hover, .proj-wrapper > a:hover {
    cursor: pointer;
    filter: invert(1);
    transition: filter 0.1s ease-in;
}

.links p {
    padding: 0 10px 20px;
}

.bio-paragraph {
    position: relative;
    overflow: auto;
}

p.about-me-text {
    color: aqua;
    text-shadow: 1px 3px 1px #800000;
}

.about-me-alt {
    color: #800000;
    text-shadow: 1px 2px 1px aqua;
}

.navbar {
    font-family: 'Fredoka', sans-serif;
    font-weight: 600;
    border-radius: 3px;
    margin-left: 25px;
}

@media (max-width: 450px) {

    .link-ul li {
        font-size: 15pt;
    }
}

@media (max-width: 615px) {
    .navbar {
        inset: 0 0 20% 0;
        width: 100%;
        max-width: 100%;
        padding: 10px 10px 20px 10px;
        margin: 0;
    }

    .links {
        max-width: 100%;
        width: 100%;
    }

    .link1, .link2, .link3, .link4, .proj-wrapper {
        font-size: 20pt;
    }
}

@media (min-width: 616px) {
    .navbar {
        /* inset: 0 0 20% 0; */
        width: 70%;
        max-width: 70%;
        max-height: 90%;
        padding: 10px 10px 40px 10px;
        overflow-y: scroll;
    }
    
    .link1, .link2, .link3, .link4, .proj-wrapper {
        font-size: 16pt;
    }

    .links p {
        font-size: 14pt;
    }

}

@media (min-width: 982px) {
    .links p {
        font-size: 16pt;
    }

    .navbar {
        overflow-y: hidden;
    }
}

@media (min-width: 1164px) {
    .links p {
        font-size: 18pt;
    }

    .navbar {
        overflow-y: hidden;
    }
}

@supports (backdrop-filter: blur(0.05rem)) {
    .navbar {
        backdrop-filter: blur(0.05rem);
        background: hsl(0 0% 100% / 0.4);
    }
}

@supports not (backdrop-filter: blur(0.05rem)) {
    .navbar {
        background: hsl(0 0% 0% / 0.5);
    }
}