::placeholder {
    font-family: 'Work Sans', sans-serif;
    font-size: 0.75rem;
    opacity: 0.5;
}

:-ms-input-placeholder {
    font-family: 'Work Sans', sans-serif;
    font-size: 0.75rem;
    opacity: 0.5;
}

::-webkit-input-placeholder {
    font-family: 'Work Sans', sans-serif;
    font-size: 0.75rem;
    opacity: 0.5;
}

@media (max-width: 615px) {
    .cm-modal {
        position: relative;
        display: flex;
        justify-content: start;
        flex-direction: column;
        max-width: 98%;
        width: 70%;
        height: 70%;
        z-index: 1001;
        background-image: url(../assets/yellow-notebook.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 3px 3px 0 3px;
        border-radius: 3px;
    }

    .cm-close-modal {
        padding: 5px 13px;
    }
    
    .input-group {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: 10px 0 10px 0;
    }

    .input-group textarea {
        width: 200px;
    }

}

@media (min-width: 616px) {
    .cm-modal {
        position: relative;
        display: flex;
        justify-content: start;
        flex-direction: column;
        width: 50%;
        height: 70%;
        z-index: 1001;
        background-image: url(../assets/yellow-notebook.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 3px 3px 0 3px;
        border-radius: 3px;
    }

    .cm-close-modal {
        padding: 5px 20px;
    }
    
    .input-group {
        display: flex;
        align-items: start;
        justify-content: space-between;
        width: 70%;
        margin: 10px 0 10px 0;
    }

    .input-group input {
        width: 200px;
    }

    .input-group textarea {
        width: 200px;
    }
    
}

.input-group label {
    font-size: 14pt;
}

.input-group input {
    border: 1px solid gray;
    border-radius: 5px !important;
    padding-left: 5px;
    height: 33px;
}

.input-group textarea {
    border: 1px solid gray;
    border-radius: 5px !important;
    padding: 8px 0 0 8px;
}

.cm-overlay {
    background: hsl(0 0% 0% / 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 1000;
}

.cm-modal-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    font-size: 10pt;
    font-weight: bold;
    background-color: hsla(46, 100%, 97%, 0.9);
}

.cm-modal-body {
    max-height: 80%;
    font-size: 9pt;
    padding: 10px;
    overflow-y: scroll;
}

.cm-form {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
}

.cm-close-modal {
    cursor: pointer;
    border-radius: 3px;
    border-color: #7DF9FF;
    background-color: #FF69B4;
    font-family: 'Fredoka', sans-serif;
    font-weight: 700;
    color:#7DF9FF;
}

.cm-submit {
    padding: 7px 40px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 3px;
    border-color: #FF69B4;
    background-color: #00A36C;
    font-family: 'Fredoka', sans-serif;
    font-weight: 700;
    font-size: 16pt;
    color: cornsilk;
    align-self: center;
}

.validation-warning {
    font-size: 8pt;
    color: red;
}

.input-validation {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.invalid-input {
    background-color: hsl(345, 100%, 70%, 0.6);
}