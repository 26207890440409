.bio-overlay {
    background: hsl(0 0% 0% / 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 1000;
}

.bio-modal {
    position: relative;
    display: flex;
    justify-content: start;
    flex-direction: column;
    width: 70%;
    height: 70%;
    z-index: 1001;
    background-image: url(../../assets/yellow-notebook.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3px 3px 0 3px;
    border-radius: 3px;
}

.bio-modal-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    font-size: 10pt;
    font-weight: bold;
    background-color: hsla(46, 100%, 97%, 0.9);
}

.bio-modal-body {
    max-height: 80%;
    font-size: 9pt;
    padding: 10px;
    overflow-y: auto;
}

.close-bio-modal {
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    border-color: #7DF9FF;
    background-color: #FF69B4;
    font-family: 'Fredoka', sans-serif;
    font-weight: 700;
    color:#7DF9FF;
}

.bio-paragraph {
    font-family: 'Fredoka', sans-serif;
    font-weight: 700;
}

.young-me-div {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.young-me-div figcaption {
    font-family: 'Manrope', sans-serif;
    font-weight: bold;
    text-align: center;
    width: 40%;
}

.young-me {
    width: 100px;
    margin: 10px 0;
    border-radius: 1.5px;
    box-shadow: 0 3px 4px #28282B;
}